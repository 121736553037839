<template>
  <main></main>
</template>

<script>
export default {
  name: "dashboard"
}
</script>

<style scoped>

</style>