<template>
  <div></div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "Forget",
  title: "Forget Password",
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      cookie: false,
      email: '',
      status: '',
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
    //reset password
    forgotPassword() {
      const _this = this
      this.auth.resetPassword(this.email)
          .then((response) => {
            if(response === 250){
              _this.status = 'ok'
            }else if(response === 251){
              _this.status = 'email'
            }else if(response === 252){
              _this.status = 'fail'
            }
          })
          .catch(() => {
            _this.status = 'fail'
          })
    }
  }
}
</script>

<style scoped>

</style>