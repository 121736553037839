<template>
  <div></div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "Reset",
  title: "Reset Password",
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      cookie: false,
      pw1: '',
      pw2: '',
      status: '',
    }
  },
  computed:{
    passwordCheck(){
      return this.pw1.length > 7 && this.pw1 === this.pw2
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
    resetPassword() {
      const _this = this
      this.auth.restorePassword(this.token, this.pw1)
          .then((response) => {
            if(response === 250){
              _this.status = 'ok'
            }else if(response === 251){
              _this.status = 'invalid'
            }else if(response === 252){
              _this.status = 'password'
            }
          })
          .catch(() => {
            _this.status = 'password'
          })
    }
  }
}
</script>

<style scoped>

</style>