<template>
  <router-view></router-view>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "Auth",
  title: "Auth",
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      page: this.$route.query.page ?? 'auth.vue',
      token: this.$route.query.resetkey,
      cookie: false,
      email: '',
      password: '',
      pw1: '',
      pw2: '',
      loginStatus: '',
      forgotStatus: '',
      resetStatus: '',
      fidoCheck: navigator.credentials && navigator.credentials.create,
    }
  },
  computed:{
    passwordCheck(){
      return this.pw1.length > 7 && this.pw1 === this.pw2
    }
  },
  mounted() {
    const _this = this
    this.config.loading = false
  },
  methods: {
    login(){
      const _this = this
      this.auth.login(this.email, this.password, this.cookie)
          .then((response) => {
            if(response === 250){
              _this.loginStatus = 'ok'
              _this.$router.push('Dashboard')
            }else if(response === 251){
              _this.loginStatus = 'user'
            }else if(response === 252){
              _this.loginStatus = 'password'
            }
          })
          .catch(() => {
            _this.loginStatus = 'fail'
          })
    },
    fidoLogin(){
      const _this = this
      this.auth.fidoLogin().then(() => _this.$router.push('/dashboard')).catch(e => {})
    },
    //reset password
    forgotPassword() {
      const _this = this
      this.auth.resetPassword(this.email)
          .then((response) => {
            if(response === 250){
              _this.forgotStatus = 'ok'
            }else if(response === 251){
              _this.forgotStatus = 'email'
            }else if(response === 252){
              _this.forgotStatus = 'fail'
            }
          })
          .catch(() => {
            _this.forgotStatus = 'fail'
          })
    },
    //restore password
    resetPassword() {
      const _this = this
      this.auth.restorePassword(this.token, this.pw1)
          .then((response) => {
            if(response === 250){
              _this.resetStatus = 'ok'
            }else if(response === 251){
              _this.resetStatus = 'invalid'
            }else if(response === 252){
              _this.resetStatus = 'password'
            }
          })
          .catch(() => {
            _this.resetStatus = 'password'
          })
    }
  }
}
</script>

<style scoped>
.Login{
  padding-top: 10vh;
}
.Login h2{
  color: rgba(0,0,0,0.8);
}
.loginmenu{
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
  padding-top: 4px;
}
.forgotpassword a{
  font-size: 0.8em;
  text-decoration: underline;
}
.rememberme span{
  font-size: 0.8em;
}
.divider{
  margin: 0 auto;
  display: block;
  max-width: 300px;
  width: 100%;
  text-align: center;
  padding: 8px 0;
}
.divider:before,
.divider:after{
  content: ' ---------- ';
  color: rgba(0,0,0,0.4);
}
#LoginButton{
  margin: 0 auto;
  display: block;
  max-width: 300px;
  width: 100%;
}
#RegisterButton{
  margin: 0 auto;
  display: block;
  max-width: calc(300px - 32px);
  width: 100%;
}
.feedbackmessage{
  font-size: 0.8em;
}
.width-300{
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}
</style>