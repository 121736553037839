<template>
  <div></div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";

export default {
  name: "Register",
  props: {
    step: {
      type: Number,
      default: 1
    }
  },
  setup(){
    const auth = authStore()
    const config = configStore()

    return {auth, config}
  },
  data(){
    return{
      email: '',
      password: '',
      password2: '',
      position: '',
      company: '',
      firstname: '',
      lastname: '',
      status: '',
      avatars: [],
      avatarIndex: 0
    }
  },
  computed:{
    passwordCheck(){
      return this.password.length > 7 && this.password === this.password2
    },
    emailCheck(){
      return this.email.match(/(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g)
    }
  },
  mounted() {
  },
  methods: {
    register(){
      const _this = this
      this.auth.register(this.email, this.firstname, this.lastname, this.password, this.avatars[this.avatarIndex], this.position, this.company)
          .then((response) => {
            if(response === 250){
              _this.status = 'ok'
              _this.$router.push('/dashboard')
            }else if(response === 251){
              _this.status = 'email'
            }
          })
          .catch(() => {
            _this.status = 'fail'
          })
    },
  }
}
</script>

<style scoped>

</style>