import { defineStore } from 'pinia'
import axios from 'axios'
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {arrayBufferToBase64, recursiveBase64StrToArrayBuffer} from "@/modules/main";

const error = new ErrorEvent('doof')

export const authStore = defineStore('auth', {
    state: () => ({
        isAuth: false,
        nodeID: null,
        cookie:  localStorage.getItem('cookie'),
        sessionID: localStorage.getItem('sessionID'),
        lastActivity: 0,
    }),
    actions: {
        /**
         * login via vue
         * @param email {string}
         * @param password {string}
         * @param cookie {bool}
         * @returns {Promise}
         */
        login(email, password, cookie) {
            const _this = this
            return new Promise((resolve, reject) => {
                const fD = new FormData()
                fD.append('email', email)
                fD.append('password', password)
                fD.append('cookie', cookie)

                const config = configStore()
                const user = userStore()
                axios.post('login/login',fD)
                    .then((response) => {
                        console.log(response.status, response.data)
                        const data = response.data
                        switch(response.status){
                            case 250:
                                user.id = data.user_id
                                user.email = data.email
                                user.firstname = data.firstname
                                user.lastname = data.lastname
                                user.avatar = data.avatar
                                user.position = data.position
                                user.company = data.company
                                user.credits = data.credits
                                config.darkMode = data.darkMode
                                config.tutorial = data.tutorial
                                _this.isAuth = true
                                _this.sessionID = data.sessionID
                                if(cookie) {
                                    _this.cookie = data.cookie
                                    localStorage.setItem('cookie', _this.cookie)
                                }
                                localStorage.setItem('sessionID',_this.sessionID)

                                resolve(response.status)
                                break;

                            case 251:
                            case 252:
                                _this.isAuth = false
                                resolve(response.status)
                                break;

                            default:
                                _this.isAuth = false
                                reject(error);
                                break;
                        }
                    })
                    .catch((e)=> {
                        reject(4)
                    })
            })
        },
        /**
         * reset Password
         * @param email
         * @returns {Promise<unknown>}
         */
        resetPassword(email) {
            const _this = this
            return new Promise((resolve, reject) => {
                const fD = new FormData()
                fD.append('email', email)

                const config = configStore()
                const user = userStore()
                axios.post('login/reset',fD)
                    .then((response) => {
                        resolve(response.status)
                        console.log(response)
                    })
                    .catch(()=> reject(error))
            })
        },
        /**
         * use key to reset password
         * @param key
         * @returns {Promise<unknown>}
         */
        restorePassword(token, password) {
            const _this = this
            return new Promise((resolve, reject) => {
                const config = configStore()
                const fD = new FormData()
                fD.append('token', token)
                fD.append('password', password)
                axios.post('login/restore',fD)
                    .then((response) => {
                        resolve(response.status)
                    })
                    .catch(()=> reject(error))
            })
        },
        /**
         * register
         * @param email
         * @param name
         * @param password
         * @param avatar
         * @param position
         * @param company
         * @returns {Promise<unknown>}
         */
        register(email, firstname, lastname, password, avatar, position, company) {
            const _this = this
            return new Promise((resolve, reject) => {
                const fD = new FormData()
                fD.append('email', email)
                fD.append('password', password)
                fD.append('firstname', firstname)
                fD.append('lastname', lastname)
                fD.append('avatar', avatar)
                fD.append('position', position)
                fD.append('company', company)

                const config = configStore()
                const user = userStore()
                axios.post('login/register',fD)
                    .then((response) => {
                        const data = response.data
                        console.log(response)
                        switch(response.status){
                            case 250:
                                user.id = data.user_id
                                user.email = data.email
                                user.firstname = data.firstname
                                user.lastname = data.lastname
                                user.avatar = data.avatar
                                user.position = data.position
                                user.company = data.company
                                user.credits = data.credits
                                config.darkMode = data.darkMode
                                config.tutorial = data.tutorial
                                _this.isAuth = true
                                _this.sessionID = data.sessionID
                                localStorage.setItem('sessionID',_this.sessionID)
                                _this.lastActivity = Date.now()
                                resolve(250)
                                break;

                            default:
                                _this.isAuth = false
                                resolve(response.status)
                                break;
                        }
                    })
                    .catch((e)=> {
                        reject(4)
                    })
            })
        },
        /**
         *
         * @returns {Promise<unknown>}
         */
        logout() {
            const _this = this
            const user = userStore()
            const config = configStore()

            axios.post('login/logout')
                .then(function (response) {
                    if (response.status === 250) {
                        localStorage.clear()
                        sessionStorage.clear()
                        localStorage.setItem('darkMode', JSON.stringify(config.darkMode))

                        user.$reset()
                        _this.$reset()

                        const cookies = document.cookie.split(';')

                        for (var i = 0; i < cookies.length; i++) {
                            const cookie = cookies[i]
                            const eqPos = cookie.indexOf('=')
                            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
                            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
                        }
                        _this.$router.push('/')
                    }
                })
                .catch((e) => {
                    console.error('doof', e)
                })
        },
        /**
         * Updates Status, checks Login
         * @returns {Promise}
         */
        update() {
            const _this = this
            const user = userStore()
            const config = configStore()
            return new Promise(function (resolve, reject) {
                if (_this.isAuth && (_this.lastActivity + 60000) > Date.now()) {
                    resolve()
                }
                else if (_this.sessionID != null) {
                    _this.lastActivity = Date.now()

                    const fD = new FormData()
                    fD.append('sessionID', _this.sessionID)
                    axios.post('login/update', fD)
                        .then((response) => {
                            console.log(response.status, response.data)
                            if (response.status === 250){
                                const data = response.data
                                user.id = data.user_id
                                user.email = data.email
                                user.firstname = data.firstname
                                user.lastname = data.lastname
                                user.avatar = data.avatar
                                user.position = data.position
                                user.company = data.company
                                user.credits = data.credits
                                config.darkMode = data.darkMode
                                config.tutorial = data.tutorial
                                if(config.cookie){
                                    _this.cookie = data.cookie
                                    localStorage.setItem('cookie', _this.cookie)
                                }
                                localStorage.setItem('sessionID',_this.sessionID)
                                _this.sessionID = data.sessionID
                                _this.isAuth = true
                                resolve(response.status)
                            }else{
                                _this.sessionID = null
                                localStorage.removeItem('sessionID')
                                _this.cookieLogin()
                                    .then(() => {
                                        _this.isAuth = true
                                        resolve()
                                    })
                                    .catch((e) => {
                                        reject(e)
                                    })
                            }
                        })
                        .catch((e) => {
                            reject(999)
                        })
                }
                else{
                    _this.cookieLogin()
                        .then(() => {
                            _this.isAuth = true
                            resolve()
                        })
                        .catch((e) => {
                            reject(999)
                        })
                }
            })
        },
        /**
         *
         * @returns {Promise<unknown>}
         */
        cookieLogin () {
            const _this = this
            return new Promise(function (resolve, reject) {
                const config = configStore()
                const user = userStore()
                try {
                    if (_this.cookie != null) {
                        const fD = new FormData()
                        fD.append('cookie', _this.cookie)
                        axios.post('login/cookie', fD)
                            .then((response) => {
                                if (response.status == 250) {
                                    const data = response.data
                                    user.id = data.user_id
                                    user.email = data.email
                                    user.firstname = data.firstname
                                    user.lastname = data.lastname
                                    user.avatar = data.avatar
                                    user.position = data.position
                                    user.company = data.company
                                    user.credits = data.credits
                                    config.darkMode = data.darkMode
                                    config.tutorial = data.tutorial
                                    _this.cookie = data.cookie
                                    _this.sessionID = data.sessionID
                                    _this.isAuth = true
                                    localStorage.setItem('cookie', _this.cookie)
                                    localStorage.setItem('sessionID',_this.sessionID)
                                    resolve(1);
                                } else {
                                    _this.$reset()
                                    user.$reset()
                                    localStorage.removeItem('sessionID')
                                    localStorage.removeItem('cookie')
                                    _this.auth = false
                                    reject(error)
                                }
                            })
                            .catch((e) => {
                                console.log(e)
                                reject(error)
                            })
                    }else{
                        _this.auth = false
                        reject(error)
                    }
                } catch (e) {
                    _this.auth = false
                    reject(error)
                }
            })
        },
        /**
         * login via fido
         * @returns {Promise<unknown>}
         */
        fidoLogin() {
            const _this = this
            return new Promise(function (resolve, reject) {
                const config = configStore()
                const user = userStore()
                try {
                    if(navigator.credentials && navigator.credentials.create && document.hasFocus()){
                        const fD2 = new FormData()
                        console.log('fidoLogin')
                        fD2.append('sessionID', _this.sessionID)
                        let sessionID
                        axios.post('login/fidoChallenge', fD2)
                            .then(response => {
                                const json = response.data
                                sessionID = json.sessionID
                                if (json.success === false) {
                                    throw new Error(json.msg);
                                }
                                // replace binary base64 data with ArrayBuffer. a other way to do this
                                // is the reviver function of JSON.parse()
                                recursiveBase64StrToArrayBuffer(json);
                                return json;
                            })
                            .then((getCredentialArgs) => {
                                console.log(getCredentialArgs)
                                return navigator.credentials.get(getCredentialArgs);
                            })
                            .then(cred => {
                                const fD3 = new FormData()
                                fD3.append('sessionID', sessionID)
                                fD3.append('id', cred.rawId ? arrayBufferToBase64(cred.rawId) : null)
                                fD3.append('clientDataJSON', cred.response.clientDataJSON  ? arrayBufferToBase64(cred.response.clientDataJSON) : null)
                                fD3.append('authenticatorData', cred.response.authenticatorData ? arrayBufferToBase64(cred.response.authenticatorData) : null)
                                fD3.append('signature', cred.response.signature ? arrayBufferToBase64(cred.response.signature) : null)
                                fD3.append('userHandle', cred.response.userHandle ? arrayBufferToBase64(cred.response.userHandle) : null)
                                axios.post('login/fidoCheck', fD3)
                                    .then(response => {
                                        console.log(response)
                                        if(response.status === 250) {
                                            _this.lastActivity = Date.now()
                                            const data = response.data
                                            user.id = data.user_id
                                            user.email = data.email
                                            user.firstname = data.firstname
                                            user.lastname = data.lastname
                                            user.avatar = data.avatar
                                            user.position = data.position
                                            user.company = data.company
                                            user.credits = data.credits
                                            config.darkMode = data.darkMode
                                            config.tutorial = data.tutorial
                                            localStorage.setItem('sessionID',_this.sessionID)
                                            _this.sessionID = data.sessionID
                                            _this.isAuth = true
                                            resolve(response.status)
                                        }
                                        else{
                                            reject(999)
                                        }
                                    })
                            })
                            .catch(function(err) {
                                console.error(err || 'unknown error occured');
                                reject(999)
                            })
                    }else{
                        reject(999)
                    }
                } catch (e) {
                    _this.auth = false
                    reject(999)
                }
            })
        }
    }
})