import { createRouter, createWebHistory } from 'vue-router'
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";

import dashBoard from "@/views/dashBoard";
import navBar from "@/components/navBar/navBar";
import footer from "@/components/footer/footer";

import auth from "@/views/auth";
import login from "@/views/auth/login";
import register from "@/views/auth/register";
import reset from "@/views/auth/reset";
import forget from "@/views/auth/forget";
import confirm from "@/views/auth/confirm";

const routes = [
  {
    path: '/',
    name: 'Website',
    components: {
    },
    redirect: () => {
      return { path: '/auth' }
    },
  },
  {
    path: '/auth',
    name: 'Auth',
    components: {
      default: auth
    },
    children: [
      {
        path: '',
        alias: '/login',
        name: 'Login',
        component: login,
        meta: { noAuth: true }
      },
      {
        path: 'register',
        name: 'Register',
        component: register,
        meta: { noAuth: true }
      },
      {
        path: 'forget',
        name: 'Forget',
        component: forget,
        meta: { noAuth: true }
      },
      {
        path: 'reset/:token',
        name: 'Reset',
        component: reset,
        props: true,
        meta: { noAuth: true }
      },
      {
        path: 'confirm',
        name: 'Confirm',
        component: confirm
      },
    ],
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    components: {
      navBar: navBar,
      default: dashBoard,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/:pathMatch(.*)*',
    alias: '/404',
    name: '404',
    redirect: () => {
      return { path: '/' }
    },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const auth = authStore()
  const config = configStore()

  config.dropDown = false
  config.loading = true

  window.scrollTo(0, 0)
  window.onscroll = function () {}

  if(to.path === '/edit' && images.currentIndex < 0) {
    next('/dashboard')
  }else if (to.matched.some(record => record.meta.auth)) {
      auth.update()
          .then(function (resolved) {
            config.loading = true
            next()
          })
          .catch(function (e) {
            next({
              path: '/auth',
              query: { redirect: to.fullPath }
            })
          })
  } else if (to.matched.some(record => record.meta.noAuth)) {
    auth.update()
        .catch((e)=> {
          //console.log(e)
        })
        .finally(function () {
          if (auth.isAuth) {
            config.loading = true
            next({
              path: '/dashboard'
            })
          } else {
            config.loading = true
            next()
          }
    })
  } else {
    auth.update()
        .catch((e)=> {
        })
    config.loading = true
    next()
  }
})

export default router
