<template>
  <div>
    test
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "Login",
  title: "Login",
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      cookie: false,
      email: '',
      password: '',
      pw1: '',
      pw2: '',
      status: '',
      fidoCheck: navigator.credentials && navigator.credentials.create,
    }
  },
  computed:{
    passwordCheck(){
      return this.pw1.length > 7 && this.pw1 === this.pw2
    }
  },
  mounted() {
    const _this = this
    this.config.loading = false
  },
  methods: {
    login(){
      const _this = this
      this.auth.login(this.email, this.password, this.cookie)
          .then((response) => {
            if(response === 250){
              _this.loginStatus = 'ok'
              _this.$router.push('Dashboard')
            }else if(response === 251){
              _this.loginStatus = 'user'
            }else if(response === 252){
              _this.loginStatus = 'password'
            }
          })
          .catch(() => {
            _this.loginStatus = 'fail'
          })
    },
    fidoLogin(){
      const _this = this
      this.auth.fidoLogin().then(() => _this.$router.push('/dashboard')).catch(e => {})
    },
    //reset password
    forgotPassword() {
      const _this = this
      this.auth.resetPassword(this.email)
          .then((response) => {
            if(response === 250){
              _this.forgotStatus = 'ok'
            }else if(response === 251){
              _this.forgotStatus = 'email'
            }else if(response === 252){
              _this.forgotStatus = 'fail'
            }
          })
          .catch(() => {
            _this.forgotStatus = 'fail'
          })
    },
    //restore password
    resetPassword() {
      const _this = this
      this.auth.restorePassword(this.token, this.pw1)
          .then((response) => {
            if(response === 250){
              _this.resetStatus = 'ok'
            }else if(response === 251){
              _this.resetStatus = 'invalid'
            }else if(response === 252){
              _this.resetStatus = 'password'
            }
          })
          .catch(() => {
            _this.resetStatus = 'password'
          })
    }
  }
}
</script>

<style scoped>

</style>