<template>
  <header>
    <div class="content-1400">
      <div class="grid-12">
        <div id="navbarLogo" class="col-sp-12 col-tb-6 row-sp-12">
          <a href="dashboard">
            <img src="/assets/images/easycrop-simple-logo.png" class="responsive-img width-150">
          </a>
        </div>
        <div id="navbarRightSide" class="col-sp-12 col-tb-6 row-sp-12 t-right pad-8">
          <div id="TopNavDropDownBtn" @click="config.dropDown = !config.dropDown">
            <div class="dropdown-profile">
              <img :src="'/assets/images/avatars/' + user.avatar" class="circular width-48 bgcolor-lightgrey is-square">
              <p class="f-right" style="padding-left: 16px;">
                Angemeldet als:
                <br>
                <b>{{ user.firstname }} {{ user.lastname }}</b>
              </p>
            </div>
            <div class="f-right">
              <span>
                <img id="DropDownIcon" src="/assets/images/dropdown-icon.png">
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <transition name="modal">
    <div v-if="config.dropDown" id="DropDownMenuArea">
      <div id="DropDownMenu">
        <ul>
          <li>
            <router-link to="/profile">
              <img src="/assets/images/profile-icon.png"> Mein Profil
            </router-link>
          </li>
          <li>
            <router-link to="/formatsets">
              <img src="/assets/images/crop-weiss.svg" style="filter: invert(1)"> Meine Format-Sets
            </router-link>
          </li>
          <li>
            <router-link to="dashboard">
              <img src="/assets/images/Upload-Kreis-petrol.svg" style="filter: brightness(0)"> Zum Dashboard
            </router-link>
          </li>
          <li @click="auth.logout()">
            <a>
              <img src="/assets/images/logout-icon.png"> Abmelden
            </a>
          </li>
        </ul>
      </div>
    </div>
  </transition>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "NavBar",
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
    }
  }
}
</script>

<style scoped>

</style>